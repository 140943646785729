import { getEventUsingQr, checkIfUserInEvent, addUserToEvent } from "@/API/api";

export default {
    name: "QR Landing",
    data() {
        return {
            isThisAvailable: null,
            title: '',
            showSignedIn: false
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    },
    mounted() {
        this.scrollToTop()
    },
    computed: {
        username() {
            return this.$store.getters.displayName;
        },
        email() {
            return this.$store.getters.email;
        },
        isLoading() {
            return this.$store.getters.isLoading;
        },
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        },
        userId() {
            return this.$store.getters.userId;
          },
        eventIdFromParameter() {
            return this.$route.params.pathId
        }
    },
    created() {
        getEventUsingQr(this.$route.params.pathId,this.$route.params.pathCode).then( result => {
            this.isThisAvailable = result
            if (!result) {
                this.$router.push('/') 
            }else {
                this.$store.dispatch('tryLogin')
                this.title = result
                this.showSignedIn = true
            }
            
        })
    },
    watch: {
        userId: function () {
                checkIfUserInEvent(this.eventIdFromParameter, this.userId).then( (val) => {
                    if (!val.userId) {
                        addUserToEvent(this.eventIdFromParameter, this.userId, this.username, this.email).then( () => {
                            this.$router.push({ path: '/'+this.eventIdFromParameter}) 
                        })
                    } else {
                        this.$router.push({ path: '/'+this.eventIdFromParameter}) 
                    }
                })
        }
    },
}