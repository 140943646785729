import { getEventStatus,uploadImageToFirestore, getThreeImages, getPhotoRemaining, checkIfUserInEvent, getCurrentQuota } from "@/API/api";

export default {
  props: {
    partyName: String,
    eventDetail: Object
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      cameraType: 'environment',
      galleryImages: {},
      remainingPhoto: '',
      isPartyQuotaFull: false
    };

  },
  mounted() {
    this.isCameraOpen = false,
      this.isPhotoTaken = false,
      this.isShotPhoto = false,
      this.isLoading = true,
      this.cameraType = 'environment'
      getEventStatus(this.$route.params.pathId).then(result => {
        if (result != 'live') {
            this.$router.push('/')
        }
      })
      checkIfUserInEvent(this.eventId,this.userId).then( result => {
        if (!result.userId) {
          this.isLoading = false
          this.$router.push('/')
        } else {
          getCurrentQuota(this.eventId).then( result => {
            this.isPartyQuotaFull = !result
          })
          getThreeImages(this.eventId).then(result => {
            this.galleryImages = result
          })
          getPhotoRemaining(this.eventId, this.userId).then(result => {
            this.remainingPhoto = result
            this.isLoading = false
          })
        }
        
      })

    
  },
  computed: {
    isSignedIn() {
      return this.$store.getters.isAuthenticated;
    },
    title() {
      return this.eventDetail.title || ''
    },
    imageWidth() {
      return screen.width * 0.8 * window.devicePixelRatio
    },
    imageHeight() {
      return screen.width * 1.06 * window.devicePixelRatio
    },
    styleImageWidth() {
      return screen.width * 0.8 
    },
    styleImageHeight() {
      return screen.width * 1.06 
    },
    userName() {
      return this.$store.getters.displayName;
    },
    email() {
      return this.$store.getters.email;
    },
    userId() {
      return this.$store.getters.userId;
    },
    eventId() {
      return this.$route.params.pathId
    },
    galleryLink() {
      return "gallery/" + this.$route.params.pathId
    },
    remainingPhotoInt() {
      return parseInt(this.remainingPhoto)
    },
    printedTitle () {
      if(this.title.length > 35) {
        return this.truncateWithEllipses(this.title,35)
      } else {
        return this.title
      }
      
    }

  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    redirectGallery() {
      this.turnOffCamera()
      this.$router.push('gallery/' + this.eventId)
    },
    toggleCamera() {
      if (this.isCameraOpen) {
        this.turnOffCamera()
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    turnOffCamera() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },
    toggleCameraType() {
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // false for not mobile device
        alert('You dont have another camera to switch to');
        return;
      }
      if (this.isCameraOpen) {
        this.toggleCamera();
      }
      this.cameraType = this.cameraType === 'user' ? 'environment' : 'user';
      this.toggleCamera();
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: { exact: this.cameraType }
        },
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isLoading = false;
          alert("Please Open this on your phone. May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (this.remainingPhotoInt > 0) {
        if (this.isCameraOpen) {
          if (!this.isPhotoTaken) {
            this.isShotPhoto = true;
  
            const FLASH_TIMEOUT = 50;
  
            setTimeout(() => {
              this.isShotPhoto = false;
            }, FLASH_TIMEOUT);
          }
  
          this.isPhotoTaken = !this.isPhotoTaken;
  
          const context = this.$refs.canvas.getContext('2d');
          context.imageSmoothingEnabled = false;
          // context.imageSmoothingQuality = 'high';
          context.drawImage(this.$refs.camera, 0, 0, this.imageWidth, this.imageHeight);
        }
      } else {
        alert("Sorry, you have no photo slot left!")
      }
      
    },
    downloadImage() {
      const download = document.getElementById('downloadPhoto');
      const canvas = document
        .getElementById('photoTaken')
        .toDataURL('image/jpeg')
        .replace('image/jpeg', 'image/octet-stream');
      download.setAttribute('href', canvas);
    },
    retakePhoto() {
      this.isPhotoTaken = false;
    },
    submitPhoto() {
        var imageId = this.userId + "-" + Date.now() / 100
        var file = document.getElementById('photoTaken').toDataURL('image/jpeg')
        uploadImageToFirestore(this.userName, imageId, this.eventId, this.userId, this.email, this.remainingPhoto, file).then( () => {
            getThreeImages(this.eventId).then(result => {
              this.galleryImages = result
            })
          const newRemaining = this.remainingPhotoInt - 1
          this.remainingPhoto = newRemaining+""
          this.isPhotoTaken = false;  
        })
        
    },
    truncateWithEllipses(text, max) {
    return text.substr(0,max-1)+(text.length>max?'...':''); 
    }

  }
}