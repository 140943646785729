<template>
  <div class="fullscreen-width">
    <div class="navbar-container" :class="{darkcolor: isDark}">
      <button @click=redirectHome>
        <img v-if="!isDark" class="logo" alt="logo" src="@/assets/memoria_logo.png" />
        <img v-if="isDark" class="logo" alt="logo" src="@/assets/memoria_logo_white.png" />
      </button>
      <div id="side_button">
        <button class="sidemenu__btn" @click="handleNavbarClick" v-bind:class="{ active: navOpen }">
          <span class="top" :class="{dark_nav: isDark}"></span>
          <span class="mid" :class="{dark_nav: isDark}"></span>
          <span class="bottom" :class="{dark_nav: isDark}"></span>
        </button>
        
        <transition name="translateX">
          <nav v-show="navOpen">
            <div id="nav_child_wrapper" >
              <img class="small-logo" alt="logo" src="@/assets/Sidebar_logo.png" />
              <button class="child_nav_button" @click=redirectHome>
                <h1>Home</h1>
                <img class="small-icon" alt="logo" src="@/assets/Sidebar_home.png" />
              </button>
              <button class="child_nav_button" @click=redirecProfile>
                <h1>Profile</h1>
                <img class="small-icon" alt="logo" src="@/assets/Sidebar_profile.png" />
              </button>
              <button class="child_nav_button" @click=redirectAbout>
                <h1>About Us</h1>
                <img class="small-icon" alt="logo" src="@/assets/Sidebar_about.png" />
              </button>
              <button class="child_nav_button" @click=redirectCreate>
                <h1>Create New Party</h1>
                <img class="small-icon" alt="logo" src="@/assets/Sidebar_create.png" />
              </button>
            </div>
          </nav>
        </transition>
        <div class="blur-bg" @click="handleNavbarClick" v-show="navOpen" @scroll.prevent>
            
          </div>
      </div>
    </div>
  </div>

</template>

<script src="./js/MainNavbar.js"></script>

<style lang="scss" scoped>
button {
  all: unset;
  cursor: pointer;
}

h1 {
  font-size: 16px;
}

.front {
  z-index: 100;
}

.child_nav_button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // background-color: blue;
}

.blur-bg {
  position: fixed;
  top: 65px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  overflow: hidden;
  
}

#nav_child_wrapper {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: flex-end;
}

.navbar-container {
  background-color: white;
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.darkcolor {
  background-color: #111827;
}

.fullscreen-width {
  background-color: #111827;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.logo {
  height: 30px;
  margin-left: 15px;
}

.small-logo {
  width: 31px;
}

.small-icon {
  width: 20px;
  height: 20px;
  padding-left: 10px;
}


#side_button {
  nav {
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    box-shadow: -4px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 65px;
    bottom: 0px;
    right: 0px;
    min-width: 70%;
    z-index: 99;
  }

  .sidemenu {
    &__btn {
      display: block;
      width: 50px;
      height: 50px;
      border: none;
      position: relative;
      appearance: none;
      cursor: pointer;
      outline: none;

      span {
        display: block;
        width: 20px;
        height: 2px;
        margin: auto;
        background: black;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all .4s ease;

        &.top {
          transform: translateY(-8px);
        }

        &.bottom {
          transform: translateY(8px);
        }
      }

      .dark_nav {
          background: white;
        }

      &.active {
        .top {
          transform: rotate(-45deg);
        }

        .mid {
          transform: translateX(-20px) rotate(360deg);
          opacity: 0;
        }

        .bottom {
          transform: rotate(45deg);
        }
      }

    }

  }
}



.translateX-enter {
  transform: translateX(200px);
  opacity: 0;
}

.translateX-enter-active,
.translateX-leave-active {
  transform-origin: top left 0;
  transition: .2s ease;
}

.translateX-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
</style>