export default {
    data() {
        return {
        }
    },
    computed: {
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        }
    },
    created() {
        this.$store.dispatch('tryLogin');
    }
}