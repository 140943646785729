<template>
  <sign-in v-if="!isSignedIn && haveRouteLink" :party-name=partyTitle></sign-in>
  <div class="hello" v-if="isSignedin | !haveRouteLink">
    <div class="container">
      <div class="padding-top">
        <text id="title">See <text id="title" class="memories">memories</text> through </text> <br>
        <text id="title">different <text id="title" class="perspectives">perspectives</text></text>
      </div>
      
    <img class="snap" alt="Snap" src="@/assets/boardingSnap.png" />
    <text id="small-title">Snap. Share. Celebrate.</text> 
    <br>
    <h1 class="warning" v-if="partyNotFound">Party Not Found</h1>
    
    <div class="inputBox">
      <div id="code-wrapper">
        <h2 id="party-code">Party Code</h2>
      </div>
      
      <input class="inputfield " :class="{ redBorder: partyNotFound}" placeholder="ABCD" v-model="eventCode" @input="typeCode($event)"
        :maxlength="4" />
      <button v-if="!isLoading" class="signButton" @click="enterClicked">
        <img class="inputfield" alt="Login" src="@/assets/EnterButton.png" />
      </button>
      <div id="create-wrapper"> 
        <button class="cursor-none">
          <a id="party-code">or</a>
        </button>
        <button v-if="!isLoading" class="signButton" @click="createPartyClicked">
        <a id="create"> Create New Party</a>
      </button>
      </div>
      
      <div v-if="isLoading">
        <img class="loadingWidth" alt="Login" src="@/assets/loading_animation.gif" />
      </div>
      
    </div>
  </div>
  </div>
</template>
  
<script src="./js/EnterCodeLanding.js"></script>

<style scoped>
.warning {
  color: #EF4444;
  font-size: 18px;
}

.padding-top {
  margin-top: 20px;
}

#title {
  font-size: 20px;
  font-family: 'Inter';
}

#small-title {
  font-size: 14px;
  font-family: 'Inter';
}

#create {
  color: black;
  text-decoration: underline;
}

#party-code {
  line-height: normal;  
  color: black;
  font-size: 14px;
}

#create-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

button {
  all: unset;
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

input {
  text-align: center
}

.memories {
  /* Fallback: Set a background color. */
  background-color: #64AEBA;
  
  /* Create the gradient. */
  background-image: linear-gradient(45deg, #64AEBA, #91C5CE);
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin: 0;
}

.perspectives {
  /* Fallback: Set a background color. */
  background-color: #64AEBA;
  
  /* Create the gradient. */
  background-image: linear-gradient(45deg, #FF8E6F, #FFBF71);
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin: 0;
}

#code-wrapper {
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 30px;
}

#or-wrapper {
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 10px;
}
.container {
  background-color: #111827;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 65px;
  /* min-height: 100vh; */
  width: 100%;
  max-width: 400px;
}
.hello {
  display: flex;
  flex-direction: column;
  background-color: #111827;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.snap {
  width: 70%;
  max-width: 300px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.inputBox {
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  background-color: #fff;
  width: 70%;
  max-width: 320px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 100px;
}

.loadingWidth {
  width: 50px;
}
.inputfield {
  border-radius: 5px;
  width: 90%;
  max-width: 280px;
  height: 35px;
  background-color: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  border-width: 1px;
  border-color: #1F2937;
}

.redBorder {
  border-color: #EF4444;
  border-style: solid;
  border-width: 3px;
}

.signButton {
  background-color: transparent;
  border: none;
  padding: 0;
  width: fit-content;
  height: fit-content;
}
</style>
  