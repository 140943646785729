export default {
    name: "About Us",
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    },
    mounted() {
        this.scrollToTop()
    }
}