import { getUserHostedParty } from "@/API/api";

export default {
    name: "CreateNewMemory",
    data() {
        return {
            hostedParties: [],
            isLoading: false,
        }
    },
    computed: {
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        },
        username() {
            return this.$store.getters.displayName;
          },
        email() {
            return this.$store.getters.email;
          },
        userId() {
            return this.$store.getters.userId;
          },
        noPartyFound() {
            return (this.hostedParties.length === 0)
        }
    },
    methods: {
        logout() {
            this.$store.dispatch("logout");
        },
        redirectCreate () {
            this.$router.push({ path: "/create"})
          },
        scrollToTop() {
            window.scrollTo(0,0);
          }
    },
    mounted() {
        this.scrollToTop()
        this.isLoading = true
        getUserHostedParty(this.userId).then( result => {
            this.hostedParties = result
            // console.log(result)
            this.isLoading = false
        })
    }
}