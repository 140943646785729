export default {
    name: "Navbar",
    data() {
        return {
            navOpen: false
        }
    },
    computed: {
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        },
        isDark() {
            return (this.$route.path == '/')
        },
        isInMainPage() {
            return (this.$route.name == 'Main Page')
        }
    },
    methods: {
        redirectHome () {
            this.$router.push({ path: "/"})
            this.navOpen = false
          },
        redirecProfile () {
            this.$router.push({ path: "/profile"})
            this.navOpen = false
          },
        redirectAbout () {
            this.$router.push({ path: "/about"})
            this.navOpen = false
          },
        redirectCreate () {
            this.$router.push({ path: "/create"})
            this.navOpen = false
          },
        handleNavbarClick () {
            this.navOpen = !this.navOpen
        }
    }

}