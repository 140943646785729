<template>
  <div class="fullscreen">
    <div class="container">
      <div class="cred">
        <div class="underline">
          <button @click=redirectCamera class="back">
            <div class="flex">
              <h1 class="buttonText underline"><img class="arrow" src="@/assets/Left_arrow.png" />Camera</h1>
            </div>
          </button>
        </div>
      </div>
      <div class="fullwidth">
        <h1>{{ partyTitle }}</h1>
      </div>

      <div class="grid" v-if="!isLoading">
        <div v-for="(image, index) in images" :key="image" class="card">
          <img :key="image.photoId" class="photo" :src=image.imageLink @click="toggleDialog(index)" />
          <h1 class="username">{{ image.username }}</h1>
        </div>
      </div>
      <div class="white" v-if="isLoading">
        <img class="loadingWidth" alt="Login" src="@/assets/loading_animation.gif" />
      </div>
      <div class="dialog" v-if="dialog">
        <div class="dialog-content">
          <!-- <button @click="toggleDialog()" class="close-icon">x</button> -->
          <div class="dialog-detail">
            <div class="close-wrapper">
              <button @click="toggleDialog()">
                <img class="close-icon" src="@/assets/close_image_button.png">
              </button>
            </div>
            
          </div>
          <div class="images-wrapper">
            <div id="arrow-button" @click="prevPhoto">
              <img src="@/assets/left_arrow_image.png">
            </div>
            
            <img id="detail-image" :src="images[pickedImage].imageLink">

            <div id="arrow-button" @click="nextPhoto">
              <img src="@/assets/right_arrow_image.png">
            </div>
            
          </div>
          <div class="dialog-detail">
            <div class="detail-wrapper">
              <div class="detail-content">
                <h1>{{ images[pickedImage].username }}</h1>
                <p><img class="icon-img" src="@/assets/calendar_icon.png">{{ photoDate(images[pickedImage].date) }}</p>
                <p><img class="icon-img" src="@/assets/clock_icon.png">{{ photoTime(images[pickedImage].date) }}</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script src="./js/TheGallery.js"></script>

<style scoped>
button {
  all: unset;
  cursor: pointer;
}

.fullwidth {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.buttonText {
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}

.underline {
  text-decoration: underline;
}

.back {
  width: 75px;
}

.arrow {
  margin-right: 5px;
  width: 12px;
  height: 10px;
}

.grid {
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.white {
  margin-top: 100px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.cred {
  margin-left: 5px;
  margin-top: 10px;
}

.loadingWidth {
  width: 70px;
}

.logo {
  height: 30px;
  margin-left: 15px;
}

.photo {
  width: 100%;
  border-radius: 5px;
}

h1 {
  color: #111827;
  font-size: 18px;
}

.navbar {
  background-color: white;
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 400px;
  min-height: 100vh;
}

.fullscreen {
  background-color: #111827;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 65px;
  right: 0px;
  left: 0px;
}

.username {
  margin-top: 2px;
}

.dialog {
  position: fixed;
  top: 50px;
  left: 0px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #999;
}

.dialog-content {
  max-width: 400px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  margin-top: 20px;
  
}

.images-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.dialog-detail {
  width: 100%;
  display: flex;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
}

.detail-wrapper {
  border-radius: 5px;
  background-color: white;
  color: black;
  width: 70%;
}

.close-wrapper {
  display: flex;
  width: 70%;
  justify-content: flex-end;
}

#detail-image {
  display: flex;
  width: 70%;
  border-radius: 10px;
}

#arrow-button {
  width: 10%;
  /* height: auto; */
  border-radius: 10px;
}

#arrow-button img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.close-icon {
  width: 30px;
}

.detail-content {
  padding: 10px;
  line-height: 0.5;
}

.detail-content p{
  display: flex;
  align-items: center;
  color: #9CA3AF;
}

.icon-img {
  height: 20px;
  margin-right: 5px;
}

</style>
