<template>
  <div class="hello">
    <img class="logo" alt="Vue logo" src="@/assets/logo.png" />
    <div id="moto">
      <text id="title">Snap. Share.</text> <br><text id="title" class="memories">Celebrate.</text>
    </div>
    <h1>{{ partyName }}</h1>
    <!-- <h1 v-if="username">{{ 'current user: ' + username }}</h1> -->
    <img class="snap" alt="Snap" src="@/assets/boardingSnap.png" />

    <br>
    <button v-if="!isSignedIn & !isLoading" @click="googleSignIn" class="signButton">
      <img class="loginButton" alt="Login" src="@/assets/loginButton.png" />
    </button>
    <div v-if="isLoading">
        <img class="loadingWidth" alt="Login" src="@/assets/loading_animation.gif" />
      </div>
  </div>
</template>
  
<script src="./js/SignIn.js"></script>

<style scoped>
h1 {
  color: white;
  font-size: 18px;
}

#title {
  font-size: 25px;
  font-family: 'Inter';
}

#moto {
  margin-top: 20px;
  margin-bottom: 20px;
}

.memories {
  /* Fallback: Set a background color. */
  background-color: #64AEBA;
  
  /* Create the gradient. */
  background-image: linear-gradient(45deg, #64AEBA, #91C5CE);
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin: 0;
}

.loadingWidth {
  width: 70px;
}
.hello {
  background-color: #111827;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 101;
}

img {
  margin-top: 30px;
}

.logo {
  width: 50px;
  max-width: 50%;
}

.snap {
  width: 80%;
  max-width: 350px;
}
.loginButton {
  width: 80%;
  max-width: 350px;
  background-color: transparent;
}

.signButton {
  background-color: transparent;
  border: none;
	padding: 0;
}
</style>
  