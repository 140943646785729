<template>
  <div class="fullscreen">
    <div class="container">
      <div class="cred">
        <div class="underline">
          <button @click=redirectProfile class="back">
            <div class="flex">
              <h1 class="buttonText underline"><img class="arrow" src="@/assets/Left_arrow.png" />Profile</h1>
            </div>
          </button>
        </div>
      </div>
      <div v-if="!isLoading" class="fullwidth">
        <h1>{{ partyTitle }}</h1>
        <br>
      <br>
      <h2>Party Code:</h2>
      <h1>{{eventDetail.eventCode}}</h1>
      <br>
      <br>
      <img v-if="partyUrl" :src="partyUrl"/>
      <br>
      <br>
      <button @click="redirectCamera">
        <a>go to camera</a>
      </button>
      </div>
      <div class="white" v-if="isLoading">
        <img class="loadingWidth" alt="Login" src="@/assets/loading_animation.gif" />
      </div>
    </div>

  </div>

</template>
  
<script src="./js/DetailEvent.js"></script>

<style scoped>
a {
  color: #111827;
  text-decoration: underline;
}
h2 {
  color: #111827;
  font-size: 14px;
}
button {
  all: unset;
  cursor: pointer;
}

.fullwidth {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.buttonText {
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}

.underline {
  text-decoration: underline;
}

.back {
  width: 75px;
  /* background-color: red; */
}

.arrow {
  margin-right: 5px;
  width: 12px;
  height: 10px;
}

.grid {
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.white {
  margin-top: 100px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.cred {
  margin-left: 5px;
  margin-top: 10px;
}

.loadingWidth {
  width: 70px;
}

.logo {
  height: 30px;
  margin-left: 15px;
}

.photo {
  width: 100%;
  border-radius: 5px;
}

h1 {
  color: #111827;
  font-size: 22px;
}

.navbar {
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 400px;
  min-height: 100vh;
}

.fullscreen {
  background-color: #111827;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 65px;
  right: 0px;
  left: 0px;
}

.username {
  margin-top: 2px;
}
</style>
  