export default {
    data() {
        return {
        }
    },
    computed: {
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        }
    },
    created() {
        this.$store.dispatch('tryLogin').then( () => {
            if (!this.isSignedIn) {
                this.$router.push('/profile')
            }
        })
    },
    mounted() {
        this.$store.dispatch('tryLogin').then( () => {
            if (!this.isSignedIn) {
                this.$router.push('/profile')
            }
        })
    }
}