import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    state() {
        return {
            username: null,
            email: null,
            uid: null,
            token: null,
            tokenExpiration: null,
            isLoading: false
        };
    },
    mutations,
    actions,
    getters
}