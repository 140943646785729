import { getGalleryImages, getEventDetail, getEventStatus } from "@/API/api";

export default {
    data() {
        return {
            images: [],
            imageLinks: {},
            imageUrlData: {},
            isLoading: true,
            eventDetail: {},
            partyTitle: '',
            isNavOpen: false,
            dialog: false,
            pickedImage: 0
        }
    },
    computed: {
        eventId() {
            return this.eventDetail.eventId
          },
          cameraLink() {
            return "/"+this.$route.params.pathId
          }
          
        },
        
    mounted() {
        this.isLoading = true
        getEventStatus(this.$route.params.pathId).then(result => {
          if (result != 'live') {
              this.$router.push('/')
          }
        })
        getEventDetail(this.$route.params.pathId).then(result => {
            if (!result) {
                this.$router.push('/')
            }
            this.eventDetail = result
            this.partyTitle = result.title
          })
        getGalleryImages(this.$route.params.pathId).then((result) => {
            if (!result) {
                this.$router.push('/')
            }
            this.images = result;
            this.isLoading = false
          });
          this.scrollToTop()
    },
    methods: {
        redirectCamera() {
            this.$router.push({ path: this.cameraLink})
          },
          redirectHome() {
            this.$router.push({ path: "/"})
          },
          scrollToTop() {
              window.scrollTo(0,0);
          },
          toggleDialog(index){
            this.pickedImage = index
            this.dialog = !this.dialog;
          },
          photoDate(timestamp) {
            return timestamp.toDate().toDateString()
          },
          photoTime(timestamp) {
            var options = { hour12: false };
            return timestamp.toDate().toLocaleTimeString('en-ID',options)
          },
          nextPhoto() {
            if (this.pickedImage !== (this.images.length - 1))
            this.pickedImage += 1
          },
          prevPhoto() {
            if (this.pickedImage !== 0) {
              this.pickedImage -= 1
            }
          }
    }
}