export default {
    setUser(state, payload) {
        state.username = payload.user.displayName;
        state.email = payload.user.email;
        state.uid = payload.user.uid;
        state.token = payload._tokenResponse.idToken;
        state.tokenExpiration = payload._tokenResponse.expiresIn;
        state.isLoading = false;
    },
    logoutUser(state) {
        state.username = null;
        state.email = null;
        state.uid = null;
        state.token = null;
        state.tokenExpiration = null;
        state.isLoading = false;
    },
    setLoading(state) {
        state.isLoading = true;
    },
};