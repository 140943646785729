import packages from "@/API/pricing";
import { createPartyMemory } from "@/API/api"

export default {
    name: "Checkout Cart",
    props: {
        data: Object
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        },
        username() {
            return this.$store.getters.displayName;
          },
        email() {
            return this.$store.getters.email;
          },
        userId() {
            return this.$store.getters.userId;
          },
        plans () {
            return packages.data
        }
    },
    created() {
        this.$store.dispatch('tryLogin');
    },
    methods: {
        handleCreateClick() {
            this.isLoading = true

            createPartyMemory(this.data.partyName,this.data.photosPerPerson,this.data.selectedPlan, this.username, this.email, this.userId).then( (result) => {
                this.isLoading = false
                console.log('debug result')
                if (this.data.selectedPlan === 0) {
                    console.log('result:')
                    console.log(result)
                    this.$router.push({ path: "/detail/"+result})
                } else {
                    this.$router.push({ path: "/profile"})
                    window.snap.pay(result);
                }
                    
            })
        },
        redirectBack() {
            this.scrollToTop()
            this.$emit('goBack')
        },
        scrollToTop() {
            window.scrollTo(0,0);
          }
    },
    mounted() {
        this.scrollToTop()
        if (!this.isSignedIn) {
            this.redirectHome()
        }
    }
}