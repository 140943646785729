<template>
  <sign-in v-if="showSignedIn" :party-name="title"></sign-in>
  <div v-if="!showSignedIn" class="fullscreen">
    <div class="container">
      <div class="center">
        <h1>{{isThisAvailable}}</h1>
        <h1>Loading</h1>
      </div>
    </div>
  </div>
</template>
  
<script src="./js/QRLanding.js"></script>

<style scoped>
h1 {
  color: #111827;
  font-size: 18px;
}

#mockup {
  width: 120px;
}

text {
  color: #111827;
  font-size: 14px;
  font-weight: 400;
}

.bottom {
  margin-bottom: 200px;
}
button {
  all: unset;
  cursor: pointer;
}

.container {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
  width: 100%;
  max-width: 400px;
}
.fullscreen {
  background-color: #111827;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 65px;
  right: 0px;
  left: 0px;
}

.content {
  width: 80%;
}

.center {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoutDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
  