import { getCurrentQuotaInt, getEventStatus } from "@/API/api";

export default {
    name: 'Party Component',
    props: {
        partyData: Object
    },
    data() {
        return {
            currentQuota: '-',
            isLoading: false,
            status: ''
        }
    },
    created() {
        this.isLoading = true
        getEventStatus(this.partyData.eventId).then( result => {
            this.status = result
                getCurrentQuotaInt(this.partyData.eventId).then( result => {
                    this.currentQuota = result
                    this.isLoading = false
                })
        })
        
    },
    methods: {
        handleClick() {
            if (this.status == "live") {
                this.$router.push({ path: "/detail/"+this.partyData.eventId})
            } 
        }
    }
}