
export default{
    data: [
        {
            id: 0,
            title: '50 Photos',
            maxPhotosString: '50 Photos',
            maxPhotos: 50,
            amount: 0,
            amountString: 'Free'
        },
        {
            id: 1,
            title: '200 Photos (Rp. 49.000)',
            maxPhotosString: '200 Photos',
            maxPhotos: 200,
            amount: 49000,
            amountString: 'Rp. 49.000'
        },
        {
            id: 2,
            title: '1000 Photos (Rp. 199.000)',
            maxPhotosString: '1000 Photos',
            maxPhotos: 1000,
            amount: 199000,
            amountString: 'Rp. 199.000'
        },
        {
            id: 3,
            title: '5000 Photos (Rp. 449.000)',
            maxPhotosString: '5000 Photos',
            maxPhotos: 5000,
            amount: 499000,
            amountString: 'Rp. 499.000'
        }
    ]
}

