export default {
    userId(state) {
        return state.uid;
    },
    email(state) {
        return state.email;
    },
    displayName(state) {
        return state.username;
    },
    isAuthenticated(state) {
        return !!state.token;
      },

};