<template>
  <div class="fullscreen">
    <div class="container">
      <div class="content logoutDiv">
        <h1>About Us</h1>
      </div>
      <div class="content">
        <text>
          <br>
          Memoria.id allows you to relive your special occasions from every angle and point of view of your party. 
                  </text>
      </div>
      <div class="center">
        <img id="mockup" src="@/assets/mockup_1.png" />
      </div>
      <div class="content">
        <text>
          <br>
      Our platform allows every attendees to take photos using their own phone, and adds them to a shared album for you and everyone to see.
      <br><br>
      With a limited number of photos allowed per user, Memoria.id captures the excitement and intimacy of a traditional disposable camera party.</text>
      <br> 
      <br>
    </div>
      <div class="center">
        <img id="mockup" src="@/assets/mockup_2.png" />
      </div>
      <div class="content">
        <text>
          <br>
          Perfect for weddings, birthdays, and any other celebration, Memoria.id helps you create lasting memories that you'll cherish for years to come. Try it out today and see how Memoria.id can make your special occasion even more unforgettable. 
        </text>
        </div>
        <div class="content logoutDiv">
        <h1>Contact Us</h1>
      </div>
      <div class="content bottom">
        <text>
          contact.memoria.id@gmail.com  
          <br>
          +62 813-9509-8451     
        </text>
      </div>
    </div>
  </div>
</template>
  
<script src="./js/AboutUs.js"></script>

<style scoped>
h1 {
  color: #111827;
  font-size: 18px;
}

#mockup {
  width: 120px;
}

text {
  color: #111827;
  font-size: 14px;
  font-weight: 400;
}

.bottom {
  margin-bottom: 200px;
}
button {
  all: unset;
  cursor: pointer;
}

.container {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
  width: 100%;
  max-width: 400px;
}
.fullscreen {
  background-color: #111827;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 65px;
  right: 0px;
  left: 0px;
}

.content {
  width: 80%;
}

.center {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoutDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
  