import { createRouter, createWebHistory } from 'vue-router';
import NotFound from './pages/NotFound.vue';
import MainPage from './pages/MainPage.vue';
import Gallery from './pages/TheGallery.vue';
import EnterCode from './pages/EnterCodeLanding.vue';
import MainCreatePage from './pages/MainCreatePage.vue';
import DetailEvent from './pages/DetailEvent.vue';
import ProfilePage from './pages/MainProfilePage.vue';
import AboutUs from './pages/AboutUs.vue';
import QRLanding from './pages/QRLanding.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Enter Code',
            component: EnterCode
        },
        {
            path: '/create',
            name: 'Create Party',
            component: MainCreatePage
        },
        {
            path: '/profile',
            name: 'Profile Page',
            component: ProfilePage
        },
        {
            path: '/about',
            name: 'About Us',
            component: AboutUs
        },
        {
            path: '/detail/:pathId',
            name: 'Detail',
            component: DetailEvent
        },
        {
            path: '/gallery/:pathId',
            name: 'Gallery',
            component: Gallery
        },
        {
            path: '/qr/:pathId/:pathCode',
            name: 'QR Landing',
            component: QRLanding
        },
        {
            path: '/:pathId',
            name: 'Main Page',
            component: MainPage
        },
        {
            path: '/:notFound(.*)',
            name: 'NotFound',
            component: NotFound
        }
    ]
});

export default router;
