<template>
  <div class="fullscreen">
    <div class="container">
      <div class="content logoutDiv min_content">
        <div id="nametag">
          <h1>{{ username }}</h1>
        <text id="email">{{ email }}</text>
        </div>
        <Button class="logoutButton" @click="logout">
          Logout
        </Button>
      </div>
      <div class="white" v-if="isLoading">
        <img class="loadingWidth" alt="Login" src="@/assets/loading_animation.gif" />
      </div>
      <h1 class="margin-top" v-if="!noPartyFound & !isLoading">{{ 'Your Parties' }}</h1>
      <text class="margin-top" v-if="noPartyFound & !isLoading">{{ 'You have no active party' }}</text>
      <div class="content" v-if="!noPartyFound & !isLoading">
        <div  v-for="prt in hostedParties" :key="prt">
          <party-card-component :party-data="prt"></party-card-component>
        </div>
      </div>
      <div v-if="noPartyFound & !isLoading" class="content center">
        <button id="create_button" @click="redirectCreate">
          <img id="button_image" alt="logo" src="@/assets/create_party.png" />
        </button>
      </div>
      
    </div>
  </div>
</template>
  
<script src="./js/ProfileComponentPage.js"></script>

<style scoped>
h1 {
  color: #111827;
  font-size: 18px;
  margin: 0;
}

#button_image {
  display: flex;
  width: 100%;
}

#email {
  font-size: 12px;
}

#create_button {
  margin-top: 50px;
  width: 130px;
}

text {
  color: #111827;
  font-size: 18px;
}

button {
  all: unset;
  cursor: pointer;
}

#nametag {
  height: min-content;
}


.full-width {
  width: 100%;
}
.container {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
  width: 100%;
  max-width: 400px;
}
.fullscreen {
  background-color: #111827;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 65px;
  right: 0px;
  left: 0px;
}

.loadingWidth {
  width: 70px;
}

.logoutButton {
  all: unset;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  text-decoration: underline;
  color: #111827;
  font-size: 14px;
  height: fit-content;
}

.content {
  width: 80%;
}

.center {

  display: flex;
  justify-content: center;
  align-items: center;
}

.logoutDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.margin-top {
  margin-top: 30px;
  margin-bottom: 20px;
}
.min_content {
  height: min-content;
}

</style>
  