
export default {
    name: "SignUp",
    props: {
        partyName: String
    },
    data() {
        return {
            error: null,
        }
    },
    computed: {
        username() {
            return this.$store.getters.displayName;
        },
        isLoading() {
            return this.$store.getters.isLoading;
        },
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        }
    },
    methods: {
        googleSignIn() {
            this.$store.dispatch("login");
        },
        logOut() {
            this.$store.dispatch("logout");
        }
    }
}