import { getEventIdFromCode, addUserToEvent, checkIfUserInEvent } from "@/API/api";

export default {
    name: "Enter Code",
    data() {
        return {
            error: null,
            eventCode: '',
            partyNotFound: false,
            isLoading: false,
            routeLink: '',
            haveRouteLink: false
        }
    },
    created() {
        this.$store.dispatch('tryLogin');
    },
    computed: {
        username() {
            return this.$store.getters.displayName;
        },
        email() {
            return this.$store.getters.email;
        },
        isLoading() {
            return this.$store.getters.isLoading;
        },
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        },
        userId() {
            return this.$store.getters.userId;
          },
    },
    watch: {
        userId: function () {
            if (this.haveRouteLink) {
                checkIfUserInEvent(this.routeLink, this.userId).then( (val) => {
                    if (!val.userId) {
                        addUserToEvent(this.routeLink,this.userId, this.username, this.email).then( () => {
                            this.$router.push(this.routeLink) 
                        })
                    } else {
                        this.$router.push(this.routeLink) 
                    }
                })
            }
        }
    },
    methods: {
        enterClicked() {
            this.eventCode = this.eventCode.toUpperCase()
            this.isLoading = true
            this.partyNotFound = false
            getEventIdFromCode(this.eventCode).then(result => {
                if (result.eventId) {
                    this.routeLink = result.eventId.replace(/\s/g, '');
                    if (this.isSignedIn) {
                        checkIfUserInEvent(this.routeLink, this.userId).then( val => {
                            if (!val.userId) {
                                addUserToEvent(this.routeLink,this.userId, this.username, this.email).then( () => {
                                    this.isLoading = false
                                    this.$router.push(this.routeLink) 
                                })
                            } else {
                                this.isLoading = false
                                this.$router.push(this.routeLink) 
                            }
                        })
                    } else {
                        this.isLoading = false
                        this.haveRouteLink = true
                    }
                }else {
                    this.partyNotFound = true
                    this.isLoading = false
                }
            })
        },
        createPartyClicked() {
            this.$router.push('create') 
        },
        typeCode(e) {
            e.target.value = e.target.value.toUpperCase()
        }
    }
}