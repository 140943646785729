import { createApp } from 'vue';
import { inject } from '@vercel/analytics';

import App from './App.vue'
import router from './router.js';
import store from './store/index.js';
import SignIn from './components/SignIn';
import CameraView from './components/CameraView';
import CreateNewMemory from './components/CreateNewMemory';
import MainNavbar from './components/MainNavbar';
import ProfileComponentPage from './components/ProfileComponentPage';
import PartyComponent from './components/PartyComponent';
import CheckoutCart from './components/CheckoutCart';

const app = createApp(App)

app.use(router);
app.use(store);

app.component('sign-in', SignIn);
app.component('camera-view', CameraView);
app.component('create-new-memory', CreateNewMemory);
app.component('main-navbar', MainNavbar);
app.component('profile-component-page', ProfileComponentPage);
app.component('party-card-component', PartyComponent);
app.component('checkout-cart', CheckoutCart);

inject();

app.mount('#app');