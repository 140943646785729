<template>
  <checkout-cart v-if="isReadyToCheckout" :data="partyData" @go-back="notReadyCheckout"></checkout-cart>
  <div class="fullscreen" v-if="!isReadyToCheckout">
    <div class="container">
      <!-- <main-navbar></main-navbar> -->
      <img class="logo-img" alt="Vue logo" src="@/assets/logo.png" />
      <h1>{{ 'Create Memories' }}</h1>
      <div class="inputBox">
        <h1 class="label">Memory Name</h1>
        <input class="inputfield padding-inside"
          placeholder="Eg. Bella’s Wedding Party" v-model="eventName" :maxlength="40" />
        <h1 class="label">Photos Per Person</h1>
        <select class="inputfield padding-inside" v-model="selectedPhotosPerPerson">
          <option>1</option>
          <option>5</option>
          <option>10</option>
          <option>20</option>
        </select>
        <h1 class="label">Maximum Total Photos</h1>
        <select class="inputfield padding-inside full-width" v-model="selectedPlan">
          <option class="full-width" v-for="plan in plans" :value="plan.id" v-bind:key="plan.id">{{ plan.title }}</option>
          <option disabled class="full-width">Contact us for more options/packages</option>
        </select>
        <button v-if="!isLoading && isFilled" class="signButton" @click="startClicked">
          <img class="inputfield full-width" alt="Login" src="@/assets/start_button.png" />
        </button>
        
        <button v-if="!isFilled" class="signButton">
          <img class="inputfield full-width" alt="Login" src="@/assets/start_button_disable.png" />
          <div v-if="isLoading">
          <img class="loadingWidth" alt="Login" src="@/assets/loading_animation.gif" />
        </div>
        </button>
        

      </div>
      <br>
      <br>
      <div class="logoutDiv">
        <h1 class="buttonText"> Signed in as {{ email }}</h1>
        <Button class="logoutButton" @click="logout">
          Logout
        </Button>
      </div>
    </div>
  </div>
</template>
  
<script src="./js/CreateNewMemory.js"></script>

<style scoped>
h1 {
  color: #111827;
  font-size: 18px;
}

.navbar {
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.home-logo {
  height: 30px;
  margin-left: 15px;
}

.label {
  margin-bottom: 1px;
  font-size: 14px;
}

button {
  all: unset;
  cursor: pointer;
}

.full-width {
  width: 100%;
}
.signButton {
  margin-top: 20px;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
}

.inputfield {
  /* -webkit-appearance: none; */
  text-decoration: none;
  color: #111827;
  border-radius: 5px;
  /* width: 100%; */
  height: 35px;
  background-color: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  border-width: 1px;
  border-color: #1F2937;
  
}

.inputBox {
  width: 80%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.container {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
  width: 100%;
  max-width: 400px;
}

.padding-inside {
  padding-left: 10px;
}

.fullscreen {
  background-color: #111827;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 65px;
  right: 0px;
  left: 0px;
}

.loadingWidth {
  width: 70px;
}


.logo-img {
  margin-top: 20px;
  width: 50px;
  max-width: 50%;
}


.logoutButton {
  all: unset;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  text-decoration: underline;
  color: #111827;
  font-size: 14px;
  height: fit-content;
}

.buttonText {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
}

.logoutDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
  