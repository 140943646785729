import { db } from '@/firebaseConfig';
import { addDoc, doc, Timestamp, setDoc, getDocs, getDoc, query, collection , orderBy, where, limit, getCountFromServer} from "firebase/firestore";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import packages from "./pricing";

function alertTest() {
    alert('try alert test 1');

}

const uploadImageToFirestore = async (username, photoId, eventId, uid, emailAddress, remainingPhoto, file) => {
    const storage = getStorage()
    const imageRef = ref(storage, eventId + "/" + photoId);
    var locationRef = doc(db, 'gallery/' + eventId + "/photos", photoId)
    var userLocationRef = doc(db, 'gallery/' + eventId + "/users", uid)
    var result = false
    uploadString(imageRef, file.split(',')[1], "base64", { contentType: 'image/png' }).then(() => {
        getDownloadURL(imageRef)
            .then((url) => {
                const data = {
                    username: username,
                    uid: uid,
                    date: new Date(),
                    timestamp: Timestamp.fromDate(new Date()),
                    photoId: photoId,
                    email: emailAddress,
                    imageLink: url
                }
                setDoc(locationRef, data).then( () => {
                    result = true
                    const newRemaining = parseInt(remainingPhoto) - 1 
                    setDoc(userLocationRef, {
                        photoRemaining: newRemaining+""
                    }, { merge: true }).catch((error) => alert(error))
                    return result
                }
                ).catch((error) => alert(error))
            }).catch((error) => alert(error))
    }).catch(() => {
        alert('error uploading image')
    })
}

const addUserToEvent = async (eventId, uid, username, email) => {
    var userLocationRef = doc(db, 'gallery/' + eventId + "/users", uid)
        setDoc(userLocationRef, {
            email: email,
            username: username,
            userId: uid,
        }, { merge: true }).catch((error) => alert(error))
    var userLocationRef2 = doc(db, 'users/' + uid + "/participant", eventId)
        setDoc(userLocationRef2, {
            email: email,
            username: username,
            eventId: eventId
        }, { merge: true }).catch((error) => alert(error))
}

const getPhotoRemaining = async (eventId, uid) => {
    var locationRef = query(doc(db,'gallery/' + eventId + '/users', uid))
    var eventRef = query(doc(db,'gallery', eventId))
    const querySnapshot = await getDoc(locationRef);

    if (querySnapshot.data().photoRemaining) {
        return querySnapshot.data().photoRemaining
    } else {
        const docSnap = await getDoc(eventRef);
        return docSnap.data().maxPhotoPerPerson
    }
}

const createPartyMemory = async (eventName, maxPhotoPerPerson, maxTotalPhotos, username, email, userId) => {

    function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    var status = true
    var rString = ''
    var locationRef = query(collection(db, 'gallery/'))
    const querySnapshot = await getDocs(locationRef);
    const result = []
    querySnapshot.forEach(async (doc) => {
        const data = doc.data().eventCode
        result.push(data)
    });

    while (status) {
        rString = randomString(4, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'); 
        if (!result.includes(rString)) {
            status = false
        }
    }
    const docRef = await addDoc(collection(db, "gallery"), {
        title: eventName,
        maxPhotoPerPerson: maxPhotoPerPerson,
        maxTotalPhotos: packages.data[maxTotalPhotos].maxPhotos ,
        eventCode: rString,
        username: username,
        email: email,
        userId: userId,
        createdTimestamp: Timestamp.fromDate(new Date())
      })

    var userLocationRef2 = doc(db, 'users/' + userId + "/host", docRef.id)
    setDoc(userLocationRef2, {
          email: email,
          username: username,
          eventId: docRef.id
      }, { merge: true }).catch((error) => alert(error))

    addUserToEvent(docRef.id ,userId,username,email)

    if ( maxTotalPhotos === 0 ) {
        const statusRef = doc(db, 'status', docRef.id)
        setDoc(statusRef, {
            status: 'settlement'
        }, { merge: true }).then( () => {
            return docRef.id
        }).catch( err => {
            alert(err)
        })
        return docRef.id
    } else {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                transactionId: docRef.id, 
                amount: packages.data[maxTotalPhotos].amount,
                name: username,
                email: email
            })
          };
    
        const response = await fetch("https://api.memoria.id/api/pay", requestOptions);
        const responseData = await response.json();
        console.log(responseData)
        console.log(responseData.result)
        return responseData.result
    }
}

const getGalleryImages = async (eventId) => {
    var locationRef = query(collection(db, 'gallery/' + eventId + "/photos"), orderBy("timestamp", "desc"))
    const querySnapshot = await getDocs(locationRef);
    const result = []
    querySnapshot.forEach(async (doc) => {
        const data = doc.data()
        result.push(data)
    });
    return result
}

const getThreeImages = async (eventId) => {
    var locationRef = query(collection(db, 'gallery/' + eventId + "/photos"), orderBy("timestamp", "desc"), limit(3))
    const querySnapshot = await getDocs(locationRef);
    const result = []
    querySnapshot.forEach(async (doc) => {
        const data = doc.data()
        result.push(data)
    });
    return result
}

const getEventDetail = async (eventId) => {
    var locationRef = query(doc(db,'gallery', eventId))
    const querySnapshot = await getDoc(locationRef);
    var data = {
        ...querySnapshot.data(),
        eventId: querySnapshot.id
    }
    return data
}
const getCurrentQuota = async (eventId) => {
    const locationRef = query(doc(db,'gallery', eventId))
    const photosRef = collection(db,'gallery/'+eventId+'/photos')
    const countSnapshot = await getCountFromServer(photosRef);
    const querySnapshot = await getDoc(locationRef);
    return (querySnapshot.data().maxTotalPhotos-countSnapshot.data().count>0)
}

const getCurrentQuotaInt = async (eventId) => {
    const photosRef = collection(db,'gallery/'+eventId+'/photos')
    const countSnapshot = await getCountFromServer(photosRef);
    return countSnapshot.data().count
}

const getEventStatus = async (eventId) => {
    const statusRef = query(doc(db,'status', eventId))
    const querySnapshot = await getDoc(statusRef);
    const status = querySnapshot.data().status
    if ((status == 'capture' || status == 'settlement')) {
        return 'live'
    }else if ((status == 'cancel' || status == 'deny' || status == 'expire')) {
        return 'failed'
    } else {
        return 'pending'
    }
}

const checkIfUserInEvent = async (eventCode, uid) => {
    var locationRef = query(collection(db,'gallery/'+eventCode+'/users'), where('userId','==',uid))
    const querySnapshot = await getDocs(locationRef);
    var data = {}
    querySnapshot.forEach((doc) => {
        data = doc.data()
    });
    return data
}

const getEventIdFromCode = async (eventCode) => {
    var locationRef = query(collection(db,'gallery'), where('eventCode','==',eventCode))
    const querySnapshot = await getDocs(locationRef);
    var data = {}
    querySnapshot.forEach((doc) => {
        data = {
            ...doc.data(),
            eventId: doc.id
        }
    });
    return data
}

const getUserHostedParty = async (userId) => {
    var result = []
    const eventsRef = query(collection(db, 'gallery/'), where('userId','==',userId), orderBy("createdTimestamp", "desc"))
    const docs = await getDocs(eventsRef)
    docs.forEach( doc => {
        var data = data = {
            ...doc.data(),
            eventId: doc.id
        }
        result.push(data)
    })
    return result
}

const getEventUsingQr = async (eventId, eventCode) => {
    var locationRef = query(collection(db,'gallery'), where('eventCode','==',eventCode))
    const querySnapshot = await getDocs(locationRef);
    var result = null
    querySnapshot.forEach((doc) => {
        if (doc.id === eventId) {
            result = doc.data().title
        }
    });
    return result
}

export {
    uploadImageToFirestore,
    alertTest,
    getGalleryImages,
    getEventDetail,
    getEventIdFromCode,
    getThreeImages,
    createPartyMemory,
    getPhotoRemaining,
    addUserToEvent,
    checkIfUserInEvent,
    getCurrentQuota,
    getUserHostedParty,
    getCurrentQuotaInt,
    getEventStatus,
    getEventUsingQr
};