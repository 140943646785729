<template>
    <div id="card" @click="handleClick">
        <div id="container">
            <div id="card_child">
                <text> {{ 'Event Code: ' + partyData.eventCode }}</text>
                <img v-if="status == 'live'" id="badge" alt="badge" src="@/assets/badge_live.png" />
                <img v-if="status == 'failed'" id="badge" alt="badge" src="@/assets/badge_failed.png" />
                <img v-if="status == 'pending'" id="badge" alt="badge" src="@/assets/badge_waiting.png" />
            </div>
            <div id="card_child">
                <text class="title_text"> {{ partyData.title }}</text>
            </div>
            <div id="card_child">
                <text> {{ 'Quota: ' + currentQuota + ' / ' + partyData.maxTotalPhotos }}</text>
            </div>
            <div id="card_child">
                <text> {{ 'Maximum Photo per Person: ' + partyData.maxPhotoPerPerson }}</text>
            </div>
            <div v-if="status == 'live'" id="card_child" class="center">
                <p id="detail_link"> Click to see detail</p>
            </div>
        </div>

    </div>

</template>

<script src="./js/PartyComponent.js"></script>

<style scoped>
.title_text {
    color: black;
    font-size: 16px;
    font-weight: 600;
}

#detail_link {
    color: black;
    text-decoration: underline;
}

text {
    color: #9CA3AF;
    font-size: 14px;
    font-weight: 400;
}

#badge {
    height: 20px;
}


#card_child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
}
.center {
    width: 100%;
    text-align: center;
    align-items: center;
}

#card {
    display: flex;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin-bottom: 20px;
    border-radius: 15px;
}

#container {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
}
</style>