<template>
  <div class="fullscreen">
    <div class="container">
      <div class="space-between">

        <Button class="galleryButton">

          <router-link @click="redirectGallery" class="no-decoration"
            :to="{ name: 'Gallery', params: { pathId: eventId }}">

            <div class="flex space-between">
              <div v-if="!isLoading">
                <text id="title">{{ printedTitle }}</text>
                <div class="flex">
                  <h1 class="buttonText"> View All Memories</h1>
                  <img class="arrow" src="@/assets/Right_arrow.png" />
                </div>
              </div>

              <div class="flex margin-small space-between">
                <div v-for="image in galleryImages" :key="image" class="card">
                  <img :key="image.imageLink" class="photo" :src=image.imageLink />
                </div>
              </div>
            </div>


          </router-link>
        </Button>

      </div>

      <div class="cameraContainer center-div" :class="{ 'black-background': isCameraOpen }">
        <div class="camera-button">
            <img v-if="isLoading" class="loadingWidth" alt="Login" src="@/assets/loading_animation.gif" />
          <button v-if="!isCameraOpen & !isLoading" type="button" class="center-button medium-button"
            :class="{ 'is-primary': !isCameraOpen, 'is-danger': isCameraOpen }" @click="toggleCamera">
            <img class="half-width" src="@/assets/openCamera.png" alt="">
          </button>
        </div>

        <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ flash: isShotPhoto }">
          <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

          <video v-show="!isPhotoTaken" ref="camera" webkit-playsinline playsinline autoplay :width="imageWidth"
            :height="imageHeight" 
            :style="{'width': `${styleImageWidth}px`, 'height': `${styleImageHeight}px`}"></video>

          <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="imageWidth" :height="imageHeight"
          :style="{'width': `${styleImageWidth}px`, 'height': `${styleImageHeight}px`}"></canvas>
        </div>
        <!-- <h1>hello</h1> -->
      </div>

      <div>

        <div class="controlDiv" v-if="isPartyQuotaFull & !isLoading" v-show="!isPhotoTaken">
          <div class="controlItem">
            <div>
              <p> Sorry, the party has reached it's maximum quota</p>
            </div>
          </div>
        </div>
        <div class="controlDiv" v-if="!isPartyQuotaFull & !isLoading" v-show="!isPhotoTaken">
          <div class="controlItem">
            <div class="padding-right">
              <h1 class="remaining"> {{ remainingPhoto }}</h1>
            </div>
            <div>
              <h1 class="smallText"> Photos <br /> Remaining</h1>
            </div>
          </div>
          <div class="controlItem">
            <Button @click="takePhoto">
              <img class="triggerButton" src="@/assets/triggerButton.png" alt="">
            </Button>
          </div>
          <div class="controlItem">
            <Button @click="toggleCameraType">
              <img class="switchButton" src="@/assets/switchCamera.png" alt="">
            </Button>
          </div>
        </div>
        <div v-if="!isPartyQuotaFull" class="controlDiv" v-show="isPhotoTaken">
          <div class="controlItem">
            <Button @click="retakePhoto">
              <img class="controlButton" src="@/assets/retake_button.png" alt="">
            </Button>
          </div>
          <div class="controlItem">
            <Button @click="submitPhoto">
              <img class="controlButton" src="@/assets/submit_button.png" alt="">
            </Button>
          </div>
        </div>
        <div class="logoutDiv">
          <h1 class="buttonText"> Signed in as {{ userName }}</h1>
          <Button class="logoutButton" @click="logout">
            Logout
          </Button>
          <button v-if="isCameraOpen" type="button" class="whiteButton"
            :class="{ 'is-primary': !isCameraOpen, 'is-danger': isCameraOpen }" @click="toggleCamera">
            <img class="small-width" src="@/assets/closeCamera.png" alt="">
          </button>
        </div>
      </div>

    </div>
  </div>
</template>
    
<script src="./js/CameraView.js"></script>
  
<style scoped>
.no-decoration {
  text-decoration: none;
}
.loadingWidth {
  width: 70px;
}

#title {
  font-family: 'Inter';
  color: white;
  font-size: 18px;
}

.photo {
  width: 40px;
  border-radius: 5px;
  margin-left: 10px;
}

.arrow {
  margin-left: 5px;
  width: 15px;
  height: 12px;
}

.margin-small {
  padding-left: 10px;
}

.flex {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.small-width {
  width: 100px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

h1 {
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 50%;
}

.controlItem {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
}
.buttonText {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
}

.smallText {
  font-size: 10px;
  font-weight: 300;
  line-height: normal;
}

.half-width {
  width: 50vw;
  max-width: 250px;
}

.controlDiv {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-around;
}

.triggerButton {
  max-width: 65px;
  max-height: 65px;
}

.switchButton {
  max-width: 45px;
  /* max-height: 45px; */
}

.controlButton {
  max-width: 100px;
}

.padding-right {
  margin-right: 5px;
}

Button {
  background-color: transparent;
  border-width: 0;
}

.cameraContainer {
  background-color: #4E4E4E;
  width: fill;
  height: fill;
}

.black-background {
  background-color: #000;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;

}

.galleryButton {
  width: 95%;
  /* background-color: red; */
  text-align: left;
  margin-top: 10px;
  margin-left: 5px;
}

.fullscreen {
  background-color: #111827;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 101;
}

.container {
  background-color: black;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: 400px;
}

.logoutDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoutButton {
  text-align: center;
  justify-content: center;
  text-decoration: underline;
  color: white;
  font-size: 14px;
  height: fit-content;
}

.remaining {
  font-size: 26px;
}


/* test */
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
}

.web-camera-container .camera-button {
  margin-bottom: 2rem;
}

.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  background-color: #fff;
  position: absolute;
}

.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}

.web-camera-container .camera-shoot,
.web-camera-container .change-camera-type {
  margin: 1rem 0;
}

.web-camera-container .camera-shoot button,
.web-camera-container .change-camera-type button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.web-camera-container .camera-shoot button img,
.web-camera-container .change-camera-type button img {
  height: 35px;
  object-fit: cover;
}

.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}

.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}

.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes preload {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
</style>
    