<template >
  <main-navbar></main-navbar>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   color: #fff;
}

body {
  display: flex;
  justify-content: center;
  background-color: gray;
}
/* @font-face {
  font-family: 'Inter', sans-serif;
} */

</style>
