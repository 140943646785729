import { getAuth, signInWithPopup, signOut, GoogleAuthProvider } from '@firebase/auth';
import firebaseConfig from '@/firebaseConfig';

firebaseConfig
const provider = new GoogleAuthProvider();
const auth = getAuth();
let timer;
export default {
    login(context) {
        context.commit('setLoading');
        signInWithPopup(auth, provider)
                .then((result) => {

                    const expiresIn = +result._tokenResponse.expiresIn * 1000
                    const expirationDate = new Date().getTime() + expiresIn;

                    localStorage.setItem('token', result._tokenResponse.idToken)
                    localStorage.setItem('username', result.user.displayName)
                    localStorage.setItem('email', result.user.email)
                    localStorage.setItem('uid', result.user.uid)
                    localStorage.setItem('tokenExpiration', expirationDate)

                    timer = setTimeout(function() {
                        context.dispatch('logout')
                    }, expiresIn)

                    context.commit('setUser', result)
                }).catch((error) => {
                    console.log(error)
                });
    },

    tryLogin(context) {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const email = localStorage.getItem('email');
        const uid = localStorage.getItem('uid');
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        const expiresIn = +tokenExpiration - new Date().getTime();

        if (expiresIn < 10000) {
            return;
        }

        timer = setTimeout(function() {
            context.dispatch('logout')
        },expiresIn)

        if (token && uid && email && username) {
            context.commit('setUser', {
                user: {
                    displayName: username,
                    email: email,
                    uid: uid
                },
                _tokenResponse: {
                    idToken: token,
                    expiresIn: null
                }
            })
        }
    },

    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer)

        signOut(auth).then(() => {
            context.commit('logoutUser');
        }).catch((error) => {
            console.log(error)
        })
    }
};