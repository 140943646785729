import { getEventDetail, getEventStatus } from "@/API/api";
const QRCode = require('qrcode')

export default {
    data() {
        return {
            isLoading: true,
            eventDetail: {},
            partyTitle: '',
            partyUrl: null
          }
    },
    computed: {
      cameraLink() {
        return '/'+this.$route.params.pathId
      }
        },
        
    mounted() {
      this.scrollToTop()
        this.isLoading = true
        getEventStatus(this.$route.params.pathId).then(result => {
          if (result != 'live') {
              this.$router.push('/')
          }
        })
        getEventDetail(this.$route.params.pathId).then(result => {
            if (!result) {
                this.$router.push('/')
            }
            this.eventDetail = result
            this.partyTitle = result.title
            QRCode.toDataURL('www.memoria.id/qr/'+result.eventId+'/'+result.eventCode).then( url => {
              this.partyUrl = url
              this.isLoading = false
            })
            
          })
    },
    methods: {
        redirectCamera() {
            this.$router.push({ path: this.cameraLink})
          },
          redirectHome() {
            this.$router.push({ path: "/"})
          },
          scrollToTop() {
              window.scrollTo(0,0);
            },
          redirectProfile() {
            this.$router.push({ path: "/profile"})
            }
    }
}