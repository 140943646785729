<template>
  <div class="fullscreen">
    <div class="container">
      
      <div class="inputBox">
        <button @click=redirectBack class="back">
            <div class="flex">
              <h1 class="buttonText underline"><img class="arrow" src="@/assets/Left_arrow.png" />Back</h1>
            </div>
          </button>
          <div class="center">
            <h1>{{ 'Payment Summary' }}</h1>
          </div>
        
        <h1 class="label">Email:</h1>
        <h1>{{ email }}</h1>
        <h1 class="label">Name:</h1>
        <h1>{{ username }}</h1>
        <h1 class="label">Memory Name:</h1>
        <h1>{{ data.partyName }}</h1>
        <h1 class="label">Photos Per Person:</h1>
        <h1>{{ data.photosPerPerson + ' Shots'}}</h1>
        <h1 class="label">Maximum Total Photos:</h1>
        <h1>{{ plans[data.selectedPlan].maxPhotosString }}</h1>
        <h1 class="label">Price:</h1>
        <h1>{{ plans[data.selectedPlan].amountString }}</h1>

        <button v-if="!isLoading" class="signButton" @click="handleCreateClick">
          <img class="inputfield full-width" alt="Login" src="@/assets/create_button.png" />
        </button>
        <div v-if="isLoading" class="center">
          <img class="loadingWidth" alt="Login" src="@/assets/loading_animation.gif" />
        </div>
        

      </div>
    </div>
  </div>
</template>
  
<script src="./js/CheckoutCart.js"></script>

<style scoped>

.underline {
  text-decoration: underline;
}

.arrow {
  margin-right: 5px;
  width: 12px;
  height: 10px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between
}
.back {
  margin-top: 30px;
  width: 75px;
}

h1 {
  color: #111827;
  font-size: 18px;
}

.navbar {
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.home-logo {
  height: 30px;
  margin-left: 15px;
}

.label {
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: 300;
}

button {
  all: unset;
  cursor: pointer;
}

.full-width {
  width: 100%;
}
.signButton {
  margin-top: 20px;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 100px;
}

.inputfield {
  /* -webkit-appearance: none; */
  text-decoration: none;
  color: #111827;
  border-radius: 5px;
  /* width: 100%; */
  height: 35px;
  background-color: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  border-width: 1px;
  border-color: #1F2937;
  
}

.inputBox {
  width: 80%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.container {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
  width: 100%;
  max-width: 400px;
}

.padding-inside {
  padding-left: 10px;
}

.fullscreen {
  background-color: #111827;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 65px;
  right: 0px;
  left: 0px;
}

.loadingWidth {
  width: 70px;
}


.logo-img {
  margin-top: 20px;
  width: 50px;
  max-width: 50%;
}


.logoutButton {
  all: unset;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  text-decoration: underline;
  color: #111827;
  font-size: 14px;
  height: fit-content;
}

.buttonText {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
}

.logoutDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.center {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
  