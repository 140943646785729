import { getEventDetail, getEventStatus } from "@/API/api";

export default {
    data() {
        return {
            eventDetail: {},
            partyTitle: 'Loading',
        }
    },
    computed: {
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        }
    },
    mounted() {
        getEventStatus(this.$route.params.pathId).then(result => {
            if (result != 'live') {
                this.$router.push('/')
            }
          })
        this.$store.dispatch('tryLogin')
        getEventDetail(this.$route.params.pathId).then(result => {
            if (!result.title) {
                this.$router.push('/')
            }
            this.eventDetail = result
            this.partyTitle = result.title
          })
    }
}