import packages from "@/API/pricing";

export default {
    name: "CreateNewMemory",
    data() {
        return {
            error: null,
            eventName: "",
            selectedPlan: 0,
            selectedPhotosPerPerson: "10",
            isLoading: false,
            isReadyToCheckout: false
        }
    },
    computed: {
        isSignedIn() {
            return this.$store.getters.isAuthenticated;
        },
        isFilled() {
            if (this.eventName != "") {
                return true
            }else {
                return false
            }
        },
        username() {
            return this.$store.getters.displayName;
          },
        email() {
            return this.$store.getters.email;
          },
        userId() {
            return this.$store.getters.userId;
          },
        plans () {
            return packages.data
        },
        partyData () {
            const data = {
                partyName: this.eventName,
                photosPerPerson: this.selectedPhotosPerPerson,
                selectedPlan: this.selectedPlan
            }
            return data
        }
    },
    methods: {
        googleSignIn() {
            this.$store.dispatch("login");
        },
        logout() {
            this.$store.dispatch("logout");
          },
        startClicked() {
            this.scrollToTop()
            this.isReadyToCheckout = true
          },
        notReadyCheckout() {
            this.scrollToTop()
            this.isReadyToCheckout = false
          },
        redirectHome() {
            this.scrollToTop()
            this.$router.push({ path: "/"})
          },
        scrollToTop() {
            window.scrollTo(0,0);
          }
    },
    mounted() {
        this.scrollToTop()
    }
}